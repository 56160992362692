/*
  AXIOS Examples: https://github.com/axios/axios#installing
  
  NOTE: This file will change which server's database we're connected to.
*/

import axios from 'axios'

export default () => {
	return axios.create({    
    // LOCAL MACHINE
    // baseURL: 'http://localhost:8083/',
        
    // DEVELOPMENT SERVER
		// baseURL: 'http://45.79.222.245:81/',

    // PRODUCTION SERVER
    baseURL: 'http://23.92.29.60:81/'
	})
}
